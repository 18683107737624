<!-- @Author: Yu_Bo 2023-09-06 14:29:18-->
<template>
    <div class="classify_add_eidt">
        <el-dialog class="zc_dialog_box" :visible.sync="dialogVisible" width="500px" :close-on-click-modal='false' @close="cancelBtn">
            <div slot="title">
                <div class="name">{{ title }}</div>
            </div>
            <div class="main_info">
                <div class="info_text">
                    <div class="t_left">顶级分类</div>
                    <div class="t_right">
                        <el-select v-model="info.parent_id" :clearable="false" placeholder="请选择分类">
                            <el-option v-for="item in list" :key="item.id" :label="item.name" :value="numFuntion(item.id)">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left"><span>*</span>分类名称</div>
                    <div class="t_right">
                        <el-input v-model="info.name" maxlength="5" show-word-limit :clearable="true" placeholder="请输入分类名称"></el-input>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left">排序</div>
                    <div class="t_right">
                        <el-input v-model="info.sort" :clearable="true" placeholder="请输入排序"></el-input>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left">状态</div>
                    <div class="t_right">
                        <el-switch v-model="info.status" :active-value="1" :inactive-value="2"></el-switch>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn" :loading="subLoading">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
let that
export default {
    components: {},
    props: {},
    data() {
        return {
            list:[],
            title:'',
            dialogVisible:false,
            // 
            info:{
                parent_id:'',
                name:'',
                sort:'',
                status:2,
            },
            // 
            subLoading:false
        };
    },

    computed: {},

    watch: {},

    created() {
        that = this
    },

    mounted() {},

    methods: {
        // 打开
        openDialog(info=''){
            if(info){
                that.title = '编辑分类'
                that.info = info
                that.info.parent_id = info.parent_id==0?'': info.parent_id
            }else{
                that.title = '添加分类'
                that.info = {
                    parent_id:'',
                    name:'',
                    sort:'',
                    status:2,
                }
            }
            that.dialogVisible = true
            that.getList()
        },
        getList(){
            var params = {}
            that.$materialApi.materialCategory(params).then(res => {
                that.loading = false
                if (res.code == 1000) {
                    that.list = res.result || []
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 取消
        cancelBtn(){
            that.info = {
                parent_id:'',
                name:'',
                sort:'',
                status:2,
            }
            that.dialogVisible = false
        },
        // 确定
        affirmBtn(){
            var obj = JSON.parse(JSON.stringify(that.info))
            if(obj.name==''){
                that.$warMsg('请输入分类名称')
            }else if(obj.id){
                // 编辑
                if(obj.parent_id=='' || obj.parent_id==null){
                    obj.parent_id = 0
                }
                that.subLoading = true
                var params = {
                    ...obj
                }
                that.$materialApi.putMaterialCategory(params,obj.id).then(res => {
                    that.subLoading = false
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.cancelBtn()
                        that.$emit('affirm')
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }else{
                // 添加
                if(obj.parent_id=='' || obj.parent_id==null){
                    obj.parent_id = 0
                }
                that.subLoading = true
                var params = {
                    ...obj
                }
                that.$materialApi.postMaterialCategory(params).then(res => {
                    that.subLoading = false
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.cancelBtn()
                        that.$emit('affirm')
                    } else {
                        that.$errMsg(res.message)
                    }
                })
                
            }
        },
        // 转换数字类型
        numFuntion(id){
            return id*1
        }
    }
}
</script>
<style lang='scss' scoped>
.classify_add_eidt{
    .main_info{
        width: 100%;
        .info_text{
            width: 100%;
            padding-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .t_left{
                // width: 80px;
                padding-right: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                text-align: right;
                span {
                    color: #e51d41;
                }
            }
            .t_right{
                width: 365px;
                .el-input {
                    width: 100%;
                }

                .el-select {
                    width: 100%;
                }
            }
        }
        .info_text:last-child{
            padding-bottom: 0;
        }
    }
}
</style>