<!-- @Author: Yu_Bo 2023-09-05 18:28:44-->
<template>
    <div class="classify_vue">
        <!-- 标题 -->
        <div class="vue_title">分类管理</div>
        <!--  -->
        <div class="classify_main">
            <div class="main_condition">
                <div class="cond_left">
                    <el-button type="primary" class="btnBgColor_blue_empty" @click="addClassify" size="small" icon="el-icon-plus">添加分类</el-button>
                    <el-input v-model="name" size="small" @keyup.enter.native="searchBtn" @clear='searchBtn' :clearable="true" placeholder="搜索分类名称"></el-input>
                </div>
            </div>
            <!--  -->
            <div class="main_table">
                <el-table :data="list" v-loading="loading" style="width: 100%" 
                    header-cell-class-name="table-header" cell-class-name="cell-class" row-key="id"
                    :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
                    <template slot="empty">
                        <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                    </template>
                    <el-table-column prop="id" label="ID"></el-table-column>
                    <el-table-column prop="name" label="分类名称"></el-table-column>
                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.status" :active-value='1' :inactive-value='2' :key="scope.row.id" @change="changeSwitch($event,scope.row,scope.$index)"></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sort" label="排序"></el-table-column>
                    <el-table-column prop="create_time" label="创建时间"></el-table-column>
                    <el-table-column prop="update_time" label="更新时间"></el-table-column>
                    <el-table-column class-name="last_class" label="操作" fixed="right" width="100">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="editClassify(scope.row)">编辑</el-button>
                            <el-button type="text" size="small" @click="delClassify(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- 删除提示 -->
        <point-out ref='dialogTip' dialogTitle="提示" type="material" @close='closeClassify' dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">请确认是否删除该分类，删除后将不可恢复</div>
            </div>
        </point-out>
        <!-- 添加/编辑 -->
        <add-edit ref="addedit" @affirm="getList"></add-edit>
    </div>
</template>

<script>
let that
import PointOut from '@/components/point_out/point_out.vue'//提示
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import AddEdit from '../components/classify/add_edit'// 添加/编辑
export default {
    components: {PointOut,EmptyData,AddEdit},
    props: {},
    data() {
        return {
            // 名称
            name:'',
            // 
            list: [],
            loading: false,
            // 
            delId:null
        };
    },

    computed: {},

    watch: {},

    created() {
        that = this
        this.getList()
    },

    mounted() {},

    methods: {
        // 数据列表
        getList(){
            that.loading = true
            var params = {
                name: that.name,
            }
            that.$materialApi.materialCategory(params).then(res => {
                that.loading = false
                if (res.code == 1000) {
                    that.list = res.result || []
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 筛选搜索
        searchBtn(){
            that.getList()
        },
        // 添加
        addClassify(){
            that.$refs.addedit.openDialog()
        },
        // 编辑
        editClassify(info){
            var obj = JSON.parse(JSON.stringify(info))
            that.$refs.addedit.openDialog(obj)
        },
        // 删除
        delClassify(info){
            if(info.children && info.children.length){
                that.$warMsg('当前分类下存在子分类不可直接删除')
            }else{
                that.delId = info.id
                that.$refs.dialogTip.openDialogBtn()
            }
        },
        // 确定删除
        closeClassify(){
            if(that.delId!=null){
                var params = {}
                that.$materialApi.delMaterialCategory(params,that.delId).then(res => {
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.getList()
                        that.delId = null
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        // 修改状态
        changeSwitch(val, info, index){
            var params = {
                id: info.id,
                status: val
            }
            that.$materialApi.categoryChangeStatus(params).then(res => {
                if (res.code == 1000) {
                    that.$succMsg(res.message)
                } else {
                    that.$errMsg(res.message)
                }
                that.getList()
            })
        },
    }
}
</script>
<style lang='scss' scoped>
.classify_vue{
    width: 100%;
    .classify_main{
        width: 100%;
        padding: 0 20px 20px;
        .main_condition {
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .cond_left {
                display: flex;
                align-items: center;
                .el-button {
                    padding: 8px 10px;
                    margin-right: 20px;
                }
                .el-select {
                    margin-right: 20px;
                    width: 160px;
                }
                .el-input {
                    margin-right: 20px;
                    width: 160px;
                }
            }
        }
    }
}
</style>